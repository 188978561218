<template>
  <div class="page-content">
    <div class="row">
      <div class="col-lg-12 mb-4">
        <div class="main-profile">
          <div class="row mb-4">
            <div class="col-lg-12">
              <div class="heading-section">
                <h4><em>Community</em> Showcase</h4>
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-md-4 mb-3" v-for="(image, index) in images" :key="index">
              <div class="image-container">
                <StoreImage class-name="" :src="image.imageSrc"></StoreImage>
                <div class="image-details text-white p-2">
                  <div class="border p-2 mb-2">
                    User details:
                    <div>User ID: {{image.userId}}</div>
                    <div>User Name: {{image.userName}}</div>
                    <div>User Email: {{image.userEmail}}</div>
                  </div>
                  <div class="border p-2 mb-2">
                    Image details:
                    <div>Image ID: {{image.imageId}}</div>
                    <div>Text: {{image.text}}</div>
                    <div>Promote: {{image.prompt}}</div>
                    <div>Created: {{image.createdAt | formatFirestoreTimestamp}}</div>
                  </div>
                  <div>
                    <button class="btn btn-primary mb-2" @click="add(image)">Add to Show Case</button>
                    <button class="btn btn-danger" @click="remove(image.imageId)">Remove from Show Case</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import StoreImage from "../components/StoreImage";
import Swal from "sweetalert2";

export default {
  name: "Showcase",
  data() {
    return {
      images: []
    }
  },
  components: {StoreImage},
  methods: {
    ...mapActions(['getAllImages', 'addShowCase', 'removeShowCase']),
    add(image) {
      this.addShowCase(image);
    },
    remove(imageId) {
      this.removeShowCase(imageId);
    }
  },
  filters: {
    formatFirestoreTimestamp(timestamp) {
      if (!timestamp) {
        return "";
      }
      const now = new Date();
      const secondsElapsed = Math.floor((now - timestamp.toDate()) / 1000);
      if (secondsElapsed < 60) {
        return `${secondsElapsed} seconds ago`;
      } else if (secondsElapsed < 3600) {
        const minutes = Math.floor(secondsElapsed / 60);
        return `${minutes} ${minutes === 1 ? 'minute' : 'minutes'} ago`;
      } else if (secondsElapsed < 86400) {
        const hours = Math.floor(secondsElapsed / 3600);
        return `${hours} ${hours === 1 ? 'hour' : 'hours'} ago`;
      } else {
        const days = Math.floor(secondsElapsed / 86400);
        return `${days} ${days === 1 ? 'day' : 'days'} ago`;
      }
    }
  },
  async mounted() {
    Swal.fire({
      title: 'Login',
      html:
          '<input id="username" class="swal2-input" placeholder="Username">' +
          '<input id="password" type="password" class="swal2-input" placeholder="Password">',
      focusConfirm: false,
      preConfirm: () => {
        const username = document.getElementById('username').value;
        const password = document.getElementById('password').value;
        return { username, password };
      }
    }).then((result) => {
      if (result.isConfirmed) {
        const { username, password } = result.value;
        // Process the username and password as needed
        console.log('Username:', username);
        console.log('Password:', password);
        if (username === "vishal" && password === "QCF123456") {
          this.getAllImages().then((images) => {
            this.images = images;
          });
        }
      }
    });
  }
}
</script>

<style lang="scss" scoped>
</style>
